import React from 'react'

import { Link } from 'gatsby'
import { css } from '@emotion/react'
import Layout from '../components/layout'
import HeadShotEmoji from '../components/headshotEmoji'
import { useEffect } from 'react'

const containerCss = css`
  font-size: 2.5em;
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-rows: 50% auto;
  margin: 1em;
  @media (max-aspect-ratio: 1/1) {
    display: block;
  }
`

const greetingCss = css`
  display: flex;
  align-items: center;
`

const ExperiencePage = () => {
  return (
    <div css={containerCss}>
      <div>Drift</div>
      <div>Social Tables</div>
    </div>
  )
}

export default ExperiencePage
